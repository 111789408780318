<template>
  <el-dialog
      title="该款式所有婚纱"
      width="70%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-table
        :data="clothesList"
        border
        height="650"
        max-height="650"
        v-loading="loading"
        :default-sort="{prop: 'id', order: 'ascending'}"
        style="width: 100%">
      <el-table-column prop="clothesAllName" label="婚纱名" align="center"/>
      <el-table-column prop="orderCount" label="订单数" align="center"/>
      <el-table-column prop="yarnCount" label="试纱数" align="center"/>
      <el-table-column prop="clothesShop" label="店铺" align="center"/>
      <el-table-column prop="clothesPosition" label="位置" align="center"/>
      <el-table-column width="300" align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="success" size="mini" @click="openStyleImage">查看图片</el-button>
            <el-button type="warning" size="mini" @click="openOperation(scope.row)">历史位置</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        title="婚纱足迹记录"
        :visible.sync="operationPageState"
        direction="rtl"
        :modal="false"
        @before-close="operationPageState=false">
      <clothesOperationList v-if="operationPageState" :clothesId="clothesId"  :state="operationPageState" @close="operationPageState=false"/>
    </el-drawer>
  </el-dialog>
</template>

<script>
import clothesOperationList from "@/components/clothes/clothes-operation-list";

export default {
name: "clothes-order-same",
  data() {
    return {
      form: {
        styleId:this.searchDate.styleId,
        dateString:this.searchDate.dateString,
        clothesName:this.searchDate.clothesName,
        date:this.searchDate.date,
        shopId:this.searchDate.shopId,
        positionId:this.searchDate.positionId,
        tenantCrop: this.searchDate.tenantCrop
      },
      loading:true,
      clothesId:0,
      clothesList:[],
      styleImageList:[],
      dialogState: this.state,
      operationPageState:false,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    searchDate:{
      type: Object,
      required: true,
      default(){return{}},
    }
  },
  components:{
    clothesOperationList
  },
  created() {
    this.queryClothesList();
    this.queryStyleImageList();
  },
  methods: {
    queryClothesList(){
      this.$axios({
        method: "GET",
        url: "/clothesAnalysis/queryClothesOrderSame",
        params: {
          styleId: this.form.styleId,
          date:this.form.dateString,
          shopId:this.form.shopId,
          positionId:this.form.positionId,
          tenantCrop:this.form.tenantCrop,
        }
      }).then(response => {
        console.log(response)
        this.loading=false;
        this.clothesList=response.data.data
      })
    },
    //打开图片弹窗
    openStyleImage(){
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    //查询款式图片
    queryStyleImageList(){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: this.form.styleId
        }
      }).then(response => {
        this.styleImageList=response.data.data;
      })
    },
    openOperation(data){
      this.clothesId=data.clothesId;
      this.operationPageState=true;
    },

    close: function () {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>

</style>